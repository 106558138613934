// Typography
// .h1 {
//     @apply text-4xl font-extrabold tracking-tighter;
// }

// .h2 {
//   @apply text-3xl font-extrabold tracking-tighter;
// }

// .h3 {
//   @apply text-3xl font-extrabold;
// }

// .h4 {
//   @apply text-2xl font-extrabold tracking-tight;
// }

// @screen md {
//   .h1 {
//     @apply text-5xl;
//   }

//   .h2 {
//     @apply text-4xl;
//   }
// }

// Buttons
.btn,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-2.5 py-2;
}

.btn-sm {
  @apply px-2 py-1;
}

.btn-xs {
  @apply px-2 py-0.5;
}

// Forms
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply text-sm text-slate-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-indigo-300 shadow-sm;
}

.form-input,
.form-textarea {
  @apply placeholder-slate-400;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-indigo-500 border border-slate-300;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
