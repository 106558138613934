:root {
  --padding: 16px;
}

details {
  /* padding: 0 var(--padding); */
}
details[open] {
  padding-bottom: var(--padding);
}

details > summary {
  display: flex;
  /* margin: 0 calc(var(--padding) * -1); */
  font-size: 24px;
  cursor: pointer;
  justify-content: space-between;
  list-style: none; /* Hides the default arrow */
}
details[open] > summary {
  border-bottom: 1px solid rgb(209, 207, 207);
  padding-bottom: 1rem;
}
/* Adds an icon when the <details> is closed... */
details > summary::after {
  content: "+";
}
/* ...and switches it when <details> is open */
details[open] > summary::after {
  content: "-";
}
/* Removes the ugly default arrow on Chrome */
details > summary::-webkit-details-marker {
  display: none;
}
